import React from "react";

const IconWarranty = ({ color = "#2A7DE1", width = "30", height = "35" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7846 11.4856C19.7846 14.8698 17.0141 17.6127 13.5959 17.6127C10.1777 17.6127 7.40723 14.8698 7.40723 11.4856C7.40723 8.10135 10.1777 5.3584 13.5959 5.3584C17.0141 5.3584 19.7846 8.10135 19.7846 11.4856Z"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8711 11.2683L12.7418 13.2439L16.3196 9.72559"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9886 1.42588L12.8043 1.8972C13.0859 1.97022 13.3837 1.95827 13.6586 1.86401L15.9087 1.08201C16.5631 0.853653 17.29 1.1152 17.6453 1.70469L18.7087 3.47048C18.8844 3.76389 19.1593 3.98561 19.4852 4.09581L21.5047 4.78885C22.0921 4.98933 22.4863 5.53765 22.4863 6.15369V8.21289C22.4863 8.58198 22.6285 8.9378 22.8859 9.20599L24.1049 10.4805C24.5796 10.9784 24.6373 11.7378 24.2417 12.2994L23.0294 14.0227C22.8658 14.2551 22.7746 14.5299 22.7679 14.8127L22.7076 17.1733C22.6915 17.8039 22.2637 18.3523 21.6495 18.5262L19.7413 19.0652C19.4369 19.1515 19.17 19.3334 18.9796 19.5817L17.6091 21.3714C17.235 21.8599 16.5913 22.0618 16.0026 21.8746L14.0635 21.2612C13.7846 21.1735 13.4856 21.1696 13.204 21.2532L10.9632 21.9104C10.3946 22.0777 9.78174 21.8878 9.41028 21.4298L7.87619 19.5392C7.72063 19.3467 7.51814 19.198 7.28749 19.105L5.43289 18.3589C4.88309 18.1385 4.5237 17.6088 4.5237 17.0206V14.8871C4.5237 14.587 4.42849 14.2949 4.25416 14.0506L2.95742 12.2424C2.6101 11.7578 2.59803 11.1112 2.92792 10.6146L4.19247 8.70546C4.34535 8.47577 4.42715 8.20758 4.43117 7.93408L4.45799 6.09527C4.46738 5.43808 4.92332 4.86984 5.56833 4.71317L7.31565 4.28699C7.64956 4.206 7.94458 4.00951 8.14707 3.73336L9.43844 1.9742C9.79113 1.49226 10.4066 1.27452 10.9886 1.42588Z"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.90095 19.0439L1 29.8432L6.40823 28.3495L9.80229 32.9605L13.8159 21.8533"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.2835 18.6807L28.4789 28.3726L22.8708 28.0872L20.5791 33.3221L13.5957 22.7077"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconWarranty;
