import React from "react";

const IconWeather = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.45311 15.1797H21.9281C21.9281 15.1797 24.7831 14.6547 24.8421 11.8577C24.8421 11.8577 24.9581 8.18071 21.6941 8.18371C21.6941 8.18371 20.8201 4.33971 16.1001 4.74771C16.1001 4.74771 14.4101 0.376714 9.33911 1.07571C9.33911 1.07571 4.38511 2.12471 4.44411 7.07871C4.44411 7.07871 0.967109 8.41971 1.00511 11.6247C1.00511 11.6247 0.772109 14.5967 3.45311 15.1797Z"
        stroke="#2A7DE1"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M4.5459 17.8931V19.8901"
        stroke="#2A7DE1"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M11.0527 17.772V19.769"
        stroke="#2A7DE1"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M17.5586 17.8931V19.8901"
        stroke="#2A7DE1"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M7.90527 22.8903V25.1293"
        stroke="#2A7DE1"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M14.4424 22.7693V25.0083"
        stroke="#2A7DE1"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M20.7666 22.8903V25.1293"
        stroke="#2A7DE1"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default IconWeather;
