import IconAiAnalaytics from "components/common/icon-components/IconAiAnalaytics";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { TitleMed, TitleSmall } from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";

const SpecCard = styled.div`
  display: flex;
  width: 100%;
  border-radius: 1.25rem;
  overflow: hidden;
  background: var(--nuetral-100);
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ImageSection = styled.div`
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4px;
  @media (max-width: 1024px) {
    width: 100%;
    height: 30%;
  }
`;

const imageClass = css`
  background-color: var(--blue-900);
  padding: 20px;
  text-align: center;
`;

const SpecSection = styled.div`
  width: 50%;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 36px;
  @media (max-width: 1024px) {
    width: 100%;
    height: 70%;
    flex-grow: 1;
  }
`;

const SpecList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  flex-grow: 1;
  justify-content: space-evenly;
`;

const SpecRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 20px;
  svg {
    flex-shrink: 0;
    margin-top: 5px;
  }
  @media (max-width: 600px) {
    align-items: flex-start;
    svg {
      margin-top: 5px;
    }
  }
`;

const CameraOveriewSpecs = ({
  images = [null, null, null, null],
  title = "Specs Title",
  subTitle = "Specs sub-title",
  specs = [
    {
      icon: <IconAiAnalaytics height="25" width="23" />,
      text: "AI Analytics included onboard for actionable insights ",
    },
  ],
}) => {
  return (
    <SectionContainer>
      <SectionInner>
        <SpecCard>
          <ImageSection>
            {images.map(image => (
              <GatsbyImage
                image={getImage(image)}
                className={imageClass}
                objectFit="cover"
              />
            ))}
          </ImageSection>
          <SpecSection>
            <TextContainer style={{ gap: "12px" }}>
              <TitleMed>{title}</TitleMed>
              <TitleSmall style={{ fontSize: "20px" }}>{subTitle}</TitleSmall>
            </TextContainer>
            <SpecList>
              {specs.map(spec => {
                const { icon, text } = spec;
                return (
                  <SpecRow>
                    {icon}
                    <span>{text}</span>
                  </SpecRow>
                );
              })}
            </SpecList>
          </SpecSection>
        </SpecCard>
      </SectionInner>
    </SectionContainer>
  );
};

export default CameraOveriewSpecs;
