import React from "react";

const IconVision = () => {
  return (
    <svg
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.0966 8.47831C25.0966 8.47831 24.7966 8.96594 24.1906 9.69684C17.9287 17.2393 6.56991 16.5902 1.002 8.42486L1 8.18813C1 8.18813 1.40399 7.58596 2.01098 6.85615C8.27189 -0.686276 19.3827 -0.308827 24.9496 7.8565C24.9996 7.9285 25.0476 8.0005 25.0966 8.07359V8.47831Z"
        stroke="#2A7DE1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.146 8.21378C19.146 11.8879 16.416 14.866 13.0481 14.866C9.68015 14.866 6.9502 11.8879 6.9502 8.21378C6.9502 4.53966 9.68015 1.56152 13.0481 1.56152C16.416 1.56152 19.146 4.53966 19.146 8.21378Z"
        stroke="#2A7DE1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4678 8.21376C16.4678 10.2745 14.9368 11.9446 13.0479 11.9446C11.1599 11.9446 9.62891 10.2745 9.62891 8.21376C9.62891 6.15307 11.1599 4.48291 13.0479 4.48291C14.9368 4.48291 16.4678 6.15307 16.4678 8.21376Z"
        fill="#2A7DE1"
      />
    </svg>
  );
};

export default IconVision;
