import React from "react";

const IconGrid = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.778 25H3.221C1.999 25 1 24 1 22.778V3.222C1 2 1.999 1 3.221 1H22.778C24 1 25 2 25 3.222V22.778C25 24 24 25 22.778 25Z"
        stroke="#2A7DE1"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path d="M1 4.9729H25" stroke="#2A7DE1" stroke-width="1.5" />
      <path d="M1 8.92712H25" stroke="#2A7DE1" stroke-width="1.5" />
      <path d="M1 12.8813H25" stroke="#2A7DE1" stroke-width="1.5" />
      <path d="M1 16.8356H25" stroke="#2A7DE1" stroke-width="1.5" />
      <path d="M1 20.7898H25" stroke="#2A7DE1" stroke-width="1.5" />
      <path d="M4.78516 1V25" stroke="#2A7DE1" stroke-width="1.5" />
      <path d="M8.81934 1V25" stroke="#2A7DE1" stroke-width="1.5" />
      <path d="M13 1V25" stroke="#2A7DE1" stroke-width="1.5" />
      <path d="M17.0352 1V25" stroke="#2A7DE1" stroke-width="1.5" />
      <path d="M20.9648 1V25" stroke="#2A7DE1" stroke-width="1.5" />
    </svg>
  );
};

export default IconGrid;
