import React from "react";

const IconSensors4 = () => {
  return (
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5.90909"
        cy="6.09098"
        r="4.90909"
        stroke="#2A7DE1"
        stroke-width="1.63636"
      />
      <circle
        cx="20.0907"
        cy="6.09098"
        r="4.90909"
        stroke="#2A7DE1"
        stroke-width="1.63636"
      />
      <circle
        cx="5.90909"
        cy="23.9091"
        r="4.90909"
        stroke="#2A7DE1"
        stroke-width="1.63636"
      />
      <circle
        cx="20.0907"
        cy="23.9091"
        r="4.90909"
        stroke="#2A7DE1"
        stroke-width="1.63636"
      />
    </svg>
  );
};

export default IconSensors4;
